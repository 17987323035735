import type { FC } from 'react';
import { useEffect } from 'react';
import GlobalWarningModal from '../GlobalWarningModal';
import { useBoolean, useDocumentVisibility } from 'ahooks';
import { getToken, ObjectKeyToHump, getLocalUserInfo, setLocalUserInfo } from '@/utils';
import { userModelContainer } from '@/store/userModel';
import { getUserInfo } from '@/service/common/Index';

const AccountLayout: FC = ({ children }) => {
  const { userInfo, saveUserInfo, clearUserInfo } = userModelContainer.useContainer();
  const documentVisibility = useDocumentVisibility();
  const [showModal, tokenAction] = useBoolean(false);

  useEffect(() => {
    if (documentVisibility === 'visible') {
      const localToken = getToken();
      const localUserInfo = getLocalUserInfo();
      if (window.location.pathname.includes('/user/login')) {
        return;
      }
      // 检测账号变更
      if (
        localToken &&
        localUserInfo &&
        userInfo.account &&
        userInfo.account !== localUserInfo.account
      ) {
        tokenAction.setTrue();
      }

      //  清空账号存储信息
      if (!localToken && userInfo.account) {
        clearUserInfo();
      }
      // 同步账号信息
      if (localToken && !userInfo.account) {
        getUserInfo()
          .then((res) => {
            if (res.code === 200) {
              const userInfo = ObjectKeyToHump(res.data);
              saveUserInfo(userInfo);
              setLocalUserInfo(userInfo);
            } else {
              clearUserInfo();
            }
          })
          .catch(() => {
            clearUserInfo();
          });
      }
    }
  }, [documentVisibility, userInfo]);

  return (
    <>
      {children}
      <GlobalWarningModal
        visible={showModal}
        title={'登录账号发生变更'}
        desc={
          '当前页面下有其他账号在不同浏览器或选项卡发生了登录操作，请刷新以同步至最新登录账号。'
        }
        onClick={() => {
          window.location.reload();
        }}
      />
    </>
  );
};
export default AccountLayout;
