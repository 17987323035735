import { FC } from 'react';
import { useEffect, useState } from 'react';
import type { HeaderProps } from '../Header';
import cx from 'classnames';
import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import type { ConsultProps } from '../Consult';
import Consult from '../Consult';
import { BrowserCompatible } from '@/utils';
import GlobalBrowserModal from '../GlobalBrowserModal';
import AccountLayout from '../AccountCheckLayout';
import styles from './index.module.less';
import type { CSSProperties } from 'react';
import { userModelContainer } from '@/store/userModel';
// import ShowModalAd from '../ShowModalAd';

interface HeaderLayoutProps extends HeaderProps {
  pageName?: string;
  LogoIcon?: any;
  popoverClass?: string;
  LayoutStyle?: CSSProperties;
  loginRedirect?: boolean;
}
interface LayoutProps {
  showModalTip?: boolean;
  showBannner?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  showConsult?: boolean;
  headerLayoutProps?: HeaderLayoutProps;
  consultProps?: ConsultProps;
  footerTheme?: 'light' | 'dark';
}
const wrapperLayout = (layoutProps?: LayoutProps): FC => {
  const {
    // showModalTip = false,
    showBannner = false,
    showHeader = true,
    showFooter = true,
    showConsult = true,
    footerTheme = 'light',
    headerLayoutProps = {},
    consultProps = {},
  } = layoutProps || {};
  return ({ children }) => {
    const [browserTip, setBrowserTip] = useState(false);
    const { markList, setBannerStatus } = userModelContainer.useContainer();
    useEffect(() => {
      const status = BrowserCompatible();
      setBrowserTip(!status);
    }, []);
    useEffect(() => {
      setBannerStatus(showBannner);
    }, [showBannner]);
    return (
      <>
        <AccountLayout>
          {browserTip && <GlobalBrowserModal />}
          {showHeader && <Header {...headerLayoutProps} />}

          {showBannner ? (
            <main
              className={cx({ [styles.fixedHeaderMainWrapper]: headerLayoutProps.mode !== 'auto' })}
              style={{ paddingTop: !markList ? 0 : 58 }}
            >
              {children}
            </main>
          ) : (
            <main
              className={cx({ [styles.fixedHeaderMainWrapper]: headerLayoutProps.mode !== 'auto' })}
            >
              {children}
            </main>
          )}
          {/* {showModalTip && <ShowModalAd />} */}
          {showFooter && <Footer theme={footerTheme} />}
          {showConsult && <Consult {...consultProps} />}
        </AccountLayout>
      </>
    );
  };
};

export default wrapperLayout;
