import React, { useState } from 'react';
import { Button, Image as AntdImage } from 'antd';
import styles from './index.module.less';

interface IProps {
  isIE11?: boolean;
}

const GlobalBrowserModal = ({ isIE11 }: IProps) => {
  const [visible, setVisible] = useState(true);

  const BrowserList = [
    {
      icon: '/images/browser/browser_ie@2x.png',
      name: 'IE浏览器',
      text: 'V11.0及以上',
    },
    {
      icon: '/images/browser/browser_google@2x.png',
      name: '谷歌浏览器',
      text: 'V65.0及以上',
    },
    {
      icon: '/images/browser/browser_qq@2x.png',
      name: 'QQ浏览器',
      text: 'V10.0及以上',
    },
    {
      icon: '/images/browser/browser_edge@2x.png',
      name: 'Edge浏览器',
      text: 'V44.0及以上',
    },
    {
      icon: '/images/browser/browser_360@2x.png',
      name: '360浏览器',
      text: '极速模式',
    },
  ];
  isIE11 && BrowserList.shift();

  function onClose() {
    setVisible(false);
  }

  return visible ? (
    <div className={styles.mainModal} style={(isIE11 && { left: '200px' }) || {}}>
      <div className={styles.tipsContent}>
        <div className={styles.hd}>
          <div className="warn">
            <AntdImage preview={false} width={24} height={24} src="/icons/warning.svg" alt="" />
          </div>
          <span className="title">为保证您的最佳使用体验，建议您更换至</span>
        </div>
        <div className={styles.browserList}>
          {BrowserList &&
            BrowserList.map((browser) => {
              return (
                <div className={styles.browserListItem} key={browser.name}>
                  <div className={styles.icon}>
                    <AntdImage preview={false} src={browser.icon} alt="" />
                  </div>
                  <div className={styles.name}>{browser.name}</div>
                  <div className={styles.text}>（{browser.text}）</div>
                </div>
              );
            })}
        </div>
        <div className={styles.btnWrapper}>
          <Button type="primary" onClick={onClose}>
            我知道了
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};
export default GlobalBrowserModal;
